.forwards-table-wrapper {
    padding: 10px;
    margin: auto;
    position: relative;
}

.forwards-table-flex {
    display: inline-flex;
    align-items: flex-start;
    gap: 5px;
}

.table-controls {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
}

.control-button {
    background-color: #f2b800;
    color: black;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.control-button:hover {
    background-color: #e0ab00;
}

.forwards-table {
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 15px;
}

.forwards-table td {
    border: 1px solid #ddd;
    padding: 4px;
    text-align: center;
    vertical-align: middle;
    min-height: 30px;
}

.header-cell {
    width: 210px;
    font-size: 16px;
}

.header-cell.first-row {
    background-color: #f2b800;
    color: black;
}

.header-cell.dark-blue {
    background-color: #1f2128;
    color: white;
}

.header-cell.light-blue {
    background-color: #414453;
    color: white;
}

.forwards-table td:not(.header-cell) {
    width: 300px;
}

.invisible-cell, .invisible-row td {
    border: none !important;
    padding: 0 !important;
    height: 20px;
}

.forwards-table td input[type="number"],
.forwards-table td input[type="date"],
.forwards-table td select {
    background: transparent;
    border: none;
    text-align: center;
    font-size: inherit;
    padding: 4px;
    box-sizing: border-box;
}

.forwards-table td select {
    background-color: #f2b800 !important;
    color: black !important;
}
.forwards-table td select option {
    background-color: #f2b800 !important;
    color: black !important;
}

.forwards-table td input[type="checkbox"] {
    width: auto;
    height: auto;
    margin: 0;
    box-sizing: border-box;
}

.react-toggle--checked .react-toggle-track {
    background-color: #6c7bbd !important;
}

.forwards-table td button {
    background: #6c7bbd;
    color: white;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
    box-sizing: border-box;
}

.forwards-table td button:disabled {
    background: #aaa;
    cursor: not-allowed;
}

.forwards-table tbody.subtable td {
  border: none !important;
}

.forwards-table tbody.subtable {
  position: relative;
}

.forwards-table tbody.subtable::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid white;
  pointer-events: none;
}

.forwards-table tbody.separator td {
  border: none !important;
}

.forwards-table > tbody.subtable:first-of-type > tr:first-child td {
  background-color: #f2b800;
  font-weight: bold;
  color: black;
}
